

import { error } from "console"
import { setAppPathAction, setCurrentPopupAction, setLoginError, setRedirectURL, setWaitingAction } from "../state/appdata"
import { setAuthTokenAction, setChallengeIdAction } from "../state/serviceData"
import { store } from "../state/store"
import { setDxlErrorAction, setEmailEnabledAction, setFactorIdAction, setFactorListAction, setIsPendingAction, setLoginStatusAction, setMarketAction, setPendingFactorListAction, setSelectedMSISDNAction, setUsernameAction } from "../state/userdata"
import { setBOSAction, setContactNumsAction } from "../state/vodaprofile"
import { AuthFactor, Factor, FactorType, UserDetails } from "./be_interfaces"
import { GL, LOGGED, LOGGED_NOT_AUTH, NOT_LOGGED, addFactor, enroll, logFE, login, loginStatus, offuscate, retrieveUser, sendOTP, setBaseUrl } from "./services"
import { tagEmailAlreadyUsedError, tagLoginPageError, tagLoginPageErrorAccountBloccato } from "./utag"

const isFullyEnrolledUser = (user: UserDetails) => {
    // check if there is at least one factor  with verified=true
    if (user.authFactors) {
        for (let f of user.authFactors) {
            if (f.verified && user.defaultFactorId == f.id) {
                return true
            }
        }
    }

    return false
}

export const userHasManyFactors = () => {
    let count = 0;
    for (let f of store.getState().userdata.mfaFactors) {
        if (f.status!=='pending') {
            count++
        }
    }
    return count > 1;
}



export const performValidation = (navigate: any) => {
    return new Promise((resolve, reject) => {
        retrieveUser(store.getState().userdata.username).then((d: UserDetails) => {
            store.dispatch(setWaitingAction(false))

            if (d.picassoNumsNonDXL) {
                store.dispatch(setBOSAction(d.picassoNumsNonDXL))
            }
            if (d.contactNums) {
                store.dispatch(setContactNumsAction(d.contactNums))
            }
            if (d.authToken) {
                store.dispatch(setAuthTokenAction(d.authToken))
            }
            if (d.mailFactorEnabled) {
                store.dispatch(setEmailEnabledAction(d.mailFactorEnabled))
            }
            if (isFullyEnrolledUser(d) && d.authFactors) {
                // we found registered factors for the user
                store.dispatch(setFactorListAction(d.authFactors))
                for (let f of d.authFactors) {
                    if (d.defaultFactorId === f.id) {
                        logFE('found default', f)
                        //store.dispatch(setWaitingAction(true))
                        if (f && f.id && (f.profileSms || f.profileEmail)) {
                            logFE('selecting the default', f)
                            let ll = (f.profileSms?.msisdn || f.profileEmail?.email)
                            if (ll) {
                                store.dispatch(setSelectedMSISDNAction(ll))
                                store.dispatch(setFactorIdAction(f.id))
                            }

                        }

                        // check if there are more than one factor enabled
                        if (userHasManyFactors()) {
                            store.dispatch(setCurrentPopupAction('INSERISCI_NUOVO_NUMERO'));
                        } else {
                            store.dispatch(setWaitingAction(true))
                            sendOTP(store.getState().userdata.username, f, store.getState().service.authToken).then(s => {
                                logFE("returned by sendOTP", s)
                                if (s.id) {
                                    store.dispatch(setWaitingAction(false))
                                    store.dispatch(setChallengeIdAction(s.id))
                                    store.dispatch(setCurrentPopupAction('VERIFICA_IDENTITA'))
                                } else {
                                    logFE("not pending")
                                    store.dispatch(setWaitingAction(false))
                                    //TODO: manage error
                                    store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                                }

                            }).catch(e => {
                                logFE("error by sendOTP", e)
                                store.dispatch(setWaitingAction(false))
                                store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                            })
                        }






                    }
                }

            } else {
                // no factors are registered for the user
                // start enroll
                if (!isFullyEnrolledUser(d) && d.authFactors) {
                    store.dispatch(setIsPendingAction(true))
                    store.dispatch(setPendingFactorListAction(d.authFactors))
                }
                store.dispatch(setAppPathAction('MFA_ENROLL'))
                navigate('/enroll')
            }
        }).catch(e => {
            store.dispatch(setWaitingAction(false))
            //TODO: manage error
            store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
        })
    })
}


export const getLoginStatus = (navigate: any) => {
    store.dispatch(setWaitingAction(true))
    const searchParams: any = new URLSearchParams(document.location.search)
    if (searchParams.get('baseURL')) {
        let URL = searchParams.get('baseURL')
        setBaseUrl(URL)
    }

    loginStatus().then((resp) => {
        if (resp.userName) {
            store.dispatch(setUsernameAction(resp.userName))
        }
        switch (resp.loginStatus) {
            case LOGGED_NOT_AUTH:
                performValidation(navigate).then(d => {
                    store.dispatch(setWaitingAction(false))
                }).catch((e) => {
                    store.dispatch(setWaitingAction(false))
                    store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                })
                break;
            case NOT_LOGGED:
                store.dispatch(setWaitingAction(false))
                break;
            case LOGGED:
                store.dispatch(setWaitingAction(false))
                let targetURL = resp.loginURL || null;

                loginEnd(targetURL)
                break;
        }
    }).catch((e) => {
        logFE('error on loginStatus', e)
        store.dispatch(setWaitingAction(false))
        store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
    })
}



export const loadUtagBusinessScripts = async () => {
    return new Promise((resolve, reject) => {
        try {
            (window as any).setUDLBusiness((e: any) => {
                resolve(null)
            });
        } catch (error) {
            console.log('error loading URAG script', error)
            reject(null)
        }

    });

}



const getErrorContent=(errorCode:string|undefined)=>{
    let dfkey=errorCode||'';
    if(store.getState().appdata.app){
        let content=GL(dfkey+'_APP')
        if(content){
            return content;
        }
    }

    return GL(dfkey) || 'errore generico';
}

export const performLogin = async (navigate: any) => {
    return new Promise((resolve, reject) => {
        store.dispatch(setWaitingAction(true))
        login(store.getState().userdata.username, store.getState().userdata.password).then(
            async (d1) => {
                store.dispatch(setLoginStatusAction('LOGGED'))
                store.dispatch(setWaitingAction(false))
                logFE('returned data from login:', d1)
                const changePassword = (d1.status === 403 && d1.errorCode === 'OTP_CHANGE')
                if (d1 && d1.status && ((d1.status < 300) || changePassword

                )) {
                    // let logStatus = store.getState().userdata.loginStatus
                    // console.log('login status:', logStatus)
                    if (d1.status === 201 || changePassword) {
                        if (!store.getState().appdata.redirectURL && d1.location) {
                            store.dispatch(setRedirectURL(d1.location));
                        }
                        if (d1.market) {
                            store.dispatch(setMarketAction(d1.market))
                            if (d1.market.toLocaleLowerCase() === 'Business'.toLocaleLowerCase()) {
                                try {
                                    store.dispatch(setWaitingAction(true))
                                    await loadUtagBusinessScripts();
                                } finally {
                                    store.dispatch(setWaitingAction(false))
                                }
                               
                            }
                        }

                        store.dispatch(setWaitingAction(true))
                        
                        performValidation(navigate).then((d) => {
                            store.dispatch(setWaitingAction(false))
                            resolve(d)
                        }).catch(e => {
                            store.dispatch(setWaitingAction(false))
                            reject(e)
                        })

                    } else if (d1.status == 200) {
                        logFE('redirecting to', d1.location)
                        loginEnd(d1.location)
                    } else {
                        store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                    }
                } else {
                    if (d1) {
                        if (d1.status == 401) {
                            logFE('error code:', d1.errorCode, GL(d1.errorCode))
                            let error_msg =getErrorContent(d1.errorCode);

                            tagLoginPageError(error_msg)
                            store.dispatch(setLoginError(error_msg))
                            resolve(d1)
                            return
                        }
                        if (d1.status == 403) {
                            console.log('loc:', d1.location)

                            if (d1.location) {
                                let isAccountPending = d1.errorCode && (d1.errorCode.indexOf('BL_ACCOUNT_PENDING') >= 0)
                                if (store.getState().appdata.app && !isAccountPending) {

                                    let error_msg = getErrorContent(d1.errorCode) ;



                                    switch (d1.errorCode) {
                                        case "ED_MFA_ERR_OTP_ATTEMPTS_EXCEEDED":
                                            tagLoginPageErrorAccountBloccato("troppi tentativi falliti, account bloccato");
                                            break;
                                        default:
                                            tagLoginPageError(error_msg);
                                            break;
                                    }

                                    //we have been called by app mobile don't do redirects
                                    store.dispatch(setLoginError(error_msg))
                                    resolve(d1)
                                    return
                                } else {

                                    window.location.href = d1.location
                                    return

                                }
                            }

                            store.dispatch(setLoginError(d1.diagnostic || 'errore generico'))
                            resolve(d1)
                            return
                        }
                    }

                    store.dispatch(setLoginStatusAction('UNLOGGED'))
                    store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                }
            }).catch(e => {
                store.dispatch(setWaitingAction(false))
                store.dispatch(setLoginStatusAction('UNLOGGED'))
                store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
            })
    })

}


export const loginEnd = (target: String | null = null) => {
    // get the target page after login

    logFE('login end....')

    let targetURL = store.getState().appdata.redirectURL
    if (!targetURL || targetURL.length == 0) {
        targetURL = target || 'https://www.vodafone.it'
    }
    logFE('switching to', targetURL)
    window.location = targetURL

}


export const createFactorForEnroll = (type: FactorType) => {
    switch (type) {
        case 'email':
            return (
                {
                    kind: 'email',
                    label: 'default',
                    profileEmail: {
                        email: store.getState().userdata.selectedMSISDN,
                        language: 'it-IT'
                    }
                }
            )
        case 'sms':
            return (
                {
                    kind: 'sms',
                    label: 'default',
                    profileSms: {
                        msisdn: store.getState().userdata.selectedMSISDN,
                        language: 'it-IT'
                    }
                }
            )
    }

}



export const doEnroll = async (type: FactorType) => {

    // check if user is pending
    if (store.getState().userdata.isPending) {

        // check is selected MSISDN is the one pending in user from DXL
        let msisdn = store.getState().userdata.selectedMSISDN
        if(type==='sms'){
            if (msisdn.indexOf('00') == 0) {
                msisdn = '+' + msisdn.substring(2)
            }
        }
       
        let numberFound = false
        let factorFound = null
        if (store.getState().userdata.pendingFactors) {
            for (let f of store.getState().userdata.pendingFactors) {
                if ((f.profileSms && (f.profileSms.msisdn.indexOf(msisdn) >= 0)) ||
                    (f.profileEmail && (f.profileEmail.email.indexOf(msisdn) >= 0))) {
                    // we can create a new challenge on this number
                    numberFound = true
                    factorFound = f
                }
            }
        }
        if (store.getState().userdata.mfaFactors) {
            for (let f of store.getState().userdata.mfaFactors) {
                if ((f.profileSms && (f.profileSms.msisdn.indexOf(msisdn) >= 0)) ||
                    (f.profileEmail && (f.profileEmail.email.indexOf(msisdn) >= 0))) {
                    // we can create a new challenge on this number
                    numberFound = true
                    factorFound = f
                }
            }
        }

        if (numberFound) {
            // let's create a new challenge
            store.dispatch(setFactorIdAction(factorFound.id));
            sendOTP(store.getState().userdata.username, factorFound, store.getState().service.authToken).then(s => {
                logFE("returned by sendOTP", s)
                if (s.id) {
                    store.dispatch(setWaitingAction(false))
                    store.dispatch(setChallengeIdAction(s.id))
                    store.dispatch(setCurrentPopupAction('VERIFICA_IDENTITA'))
                } else {
                    logFE("not pending")
                    store.dispatch(setWaitingAction(false))
                    //TODO: manage error
                    store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                }

            }).catch(e => {
                logFE("error by sendOTP", e)
                store.dispatch(setWaitingAction(false))
                store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
            })



        } else {
            // let's add a new Factor to the user
            let r = null
            try {
                r = await addFactor(store.getState().userdata.username, createFactorForEnroll(type), store.getState().service.authToken);
            } catch (e: any) {
                // do something in case of error
                logFE('catch enroll:', e)
                if (e.error) {
                    store.dispatch(setDxlErrorAction(e.error))
                    if (e.error === DXL_EMAIL_NOT_ALLOWED_ERROR) {
                        tagEmailAlreadyUsedError();
                    }
                    return false
                } else {
                    store.dispatch(setWaitingAction(false))
                    store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'));
                    return false
                }
            }

            if (r) {
                store.dispatch(setWaitingAction(false))
                if (r?.id) {
                    store.dispatch(setChallengeIdAction(r.id))
                    store.dispatch(setFactorListAction([r.factor]))
                    if (r.factor.id) {
                        store.dispatch(setFactorIdAction(r.factor.id))
                    }
                    store.dispatch(setCurrentPopupAction('VERIFICA_IDENTITA'));
                } else {
                    store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                }

            }
        }

    } else {
        let d = null
        try {
            d = await enroll(store.getState().userdata.username, createFactorForEnroll(type),
                store.getState().service.authToken)
        } catch (e: any) {
            // do something in case of error
            logFE('catch enroll:', e)
            if (e.error) {
                store.dispatch(setDxlErrorAction(e.error))
                return false
            } else {
                store.dispatch(setWaitingAction(false))
                store.dispatch(setCurrentPopupAction('ENROLLMENT_ERROR'));
                return false
            }
        }


        //    .catch(e => {

        //     // do something in case of error
        //     logFE('catch enroll:',e)
        //     if(e.error){
        //         store.dispatch(setDxlErrorAction(e.error))
        //         return false
        //     }else{
        //         store.dispatch(setWaitingAction(false))
        //         store.dispatch(setCurrentPopupAction('ENROLLMENT_ERROR'));
        //         return false
        //     }

        // })
        store.dispatch(setWaitingAction(false))

        if (d) {

            logFE('response enroll :', d)
            if (d.status && d.id) {
                if (d.factor && d.factor.id && d.id) {
                    store.dispatch(setIsPendingAction(true));
                    store.dispatch(setChallengeIdAction(d.id))
                    store.dispatch(setFactorListAction([d.factor]))
                    store.dispatch(setFactorIdAction(d.factor.id))
                } else {
                    logFE('error invoking enroll', d)
                    store.dispatch(setCurrentPopupAction('ENROLLMENT_ERROR'))
                    return false;
                }
                store.dispatch(setCurrentPopupAction('VERIFICA_IDENTITA'));

            } else {
                if (d.reason && d.reason === 'Validation failed') {
                    logFE('number id not valid', d)
                    // store.dispatch(setCurrentPopupAction('ACCESSO_NON_DISPONIBILE'))
                    store.dispatch(setCurrentPopupAction('NUMERO_NON_VALIDO'))
                } else {
                    logFE('status is not pending', d)
                    store.dispatch(setCurrentPopupAction('ENROLLMENT_ERROR'))
                }

            }
        } else {
            logFE('response is empty', d)
            store.dispatch(setCurrentPopupAction('ENROLLMENT_ERROR'));
        }

    }
    return true

}


export const isMobile = () => {
    return store.getState().appdata.app
}



export const clearMSISDNSelection = () => {
    try {
        store.dispatch(setSelectedMSISDNAction(''));
        store.dispatch(setFactorIdAction(''));
    } catch (error) {

    }
}

export const isSelectedAValidEmail = () => {
    let answer = false;
    try {
        let n: string | undefined = store.getState().userdata.selectedMSISDN
        let pcs = n?.split('@')
        if (pcs && pcs.length > 1) {
            let sufx = pcs[1]
            let sfxpcs = sufx.split('.')
            if (sfxpcs && sfxpcs.length > 1) {
                return true;
            }
        }
    } catch (error) {

    }


    return answer;
    //TODO: implement check
}


export const isSelectedfactorEmail = () => {


    console.log('\n\n\n  **** in is Selected\n\n\n\n')
    let answer = false
    try {
        const factId = store.getState().userdata.selectedFactorId
        if (factId) {
            const facts = store.getState().userdata.mfaFactors
            let sff = facts.filter((f: Factor) => f.id === factId);
            if (sff && sff.length > 0) {
                let sf = sff[0]
                return sf.kind && sf.kind.toLowerCase() === 'email'
            }
        }
        const msisdn = store.getState().userdata.selectedMSISDN
        if(msisdn){
            const facts = store.getState().userdata.pendingFactors
            let sff = facts.filter((f: Factor) => {
                let answer=false;
                if(f.kind?.toLowerCase()==='email'){
                    if(f.profileEmail && f.profileEmail.email===msisdn)  {
                        return true;
                    }
                }else  if(f.kind?.toLowerCase()==='sms'){
                    if(f.profileSms && f.profileSms.msisdn===msisdn)  {
                        return false;
                    }
                }
            });
            if (sff && sff.length > 0) {
                let sf = sff[0]
                return sf.kind && sf.kind.toLowerCase() === 'email'
            }
        }
    } catch (error) {

    }




    return answer
}


export const isNumberMSISDN = () => {

    let n: string | undefined = store.getState().userdata.selectedMSISDN

    if (!n || n?.length < 5) {
        return false;
    }

    if ((n?.indexOf('+') === 0 && n.indexOf('+39') != 0) || (n?.indexOf('00') == 0 && n.indexOf('0039') != 0)) {
        // not an italian number do not perform any check on format
        return true
    }

    if (n) {
        if (n.indexOf('+39') == 0) {
            n = n.substring(3)
        }
        if (n.indexOf('0039') == 0) {
            n = n.substring(4)
        }
        if (n.indexOf('+') >= 0) {
            return false
        }
        if (n.length < 9 || n.length > 10 || n.indexOf('3') != 0) {
            return false
        }
    } else {
        return false
    }


    return true
}


export const thereIsOnlyOneDXLEnrolled = () => {
    if (store.getState().userdata.mfaFactors && store.getState().userdata.mfaFactors.length > 1) {
        return false
    }
    return true
}

export const getOffuscatedFactorNum = () => {
    const f: Factor = getSelectedFactor();
    switch (f.kind?.toUpperCase()) {
        case 'SMS':
            return offuscate(f.profileSms?.msisdn || '', 4)
            break;
        case 'EMAIL':
            let email = f.profileEmail?.email || '';
            return offuscate(email, email.length - 5)
            break;
        default:
            return '';
    }
}

export const getMSISDNFromFactor=(f:Factor)=>{
    switch (f.kind?.toUpperCase()) {
        case 'SMS':
            return f.profileSms?.msisdn || ''
            break;
        case 'EMAIL':
            let email = f.profileEmail?.email || '';
            return email
            break;
        default:
            return '';
    }
}



export const getSelectedFactor = () => {
    const u = store.getState().userdata;

    if (u.selectedFactorId && u.selectedFactorId !== '') {
        let f = u.mfaFactors?.filter((ff: Factor) => ff.id === u.selectedFactorId);
        if (f && f.length > 0) {
            return f[0];
        }
    }
    if(u.selectedMSISDN && u.selectedMSISDN!==''){
        let f = u.pendingFactors?.filter((ff: Factor) => {
            if(ff.kind?.toLowerCase()==='sms' && ff.profileSms){
                if(u.selectedMSISDN===ff.profileSms.msisdn){
                    return true
                }
            }
            if(ff.kind?.toLowerCase()==='email' && ff.profileEmail){
                if(u.selectedMSISDN===ff.profileEmail.email){
                    return true
                }
            }
        });
        if (f && f.length > 0) {
            return f[0];
        }
    }
    return null;
}

export const isSelectedFactorEmail = () => {
    return getSelectedFactor().kind.toLowerCase() === 'email';
}

export const getDefaultFactor=()=>{
    
}


export const DXL_EMAIL_NOT_ALLOWED_ERROR = 'DXL409';

export const getMessageFromDXLError = (dxlErr: String) => {
    switch (dxlErr) {
        case DXL_EMAIL_NOT_ALLOWED_ERROR:
            return GL('ED_MFA_EMAIL_NOT_ALLOWED_ERR')
        default:
            return 'Errore generico'
    }
}