import { stat } from "fs";
import { login } from "../services/services"
import { Action } from "./action"


export type LOGIN_STATUS = 'LOGGED' | 'UNLOGGED' | 'REQUESTING_OTP';

const InitialState = {
    username: "",
    password: "",
    mfatoken: "",
    loginStatus: 'UNLOGGED',
    selectedMSISDN: "",
    selectedFactorId: "",
    mfaFactors: [],
    emailEnabled:false,
    navigate: null,
    isPending: false,
    pendingFactors: [],
    market: 'CONSUMER',
    dxlError:""
}





const SET_USERNAME_ACTION_TYPE: string = 'SET_USERNAME'
const SET_PASSWORD_ACTION_TYPE: string = 'SET_PASSWORD'
const SET_LOGIN_STATUS_TYPE: string = 'SET_LOGIN_STATUS'
// const SET_SELECTED_FACTOR_TYPE: string = 'SET_SELECTED_FACTOR'
const SET_FACTOR_LIST_ACTION_TYPE: string = 'SET_FACTOR_LIST'
const SET_PENDING_FACTOR_LIST_ACTION_TYPE: string = 'SET_PENDING_FACTOR_LIST'

const SET_NAVIGATE_ACTION_TYPE: string = 'SET_NAVIGATE'

const SET_FACTOR_ID_ACTION_TYPE: string = 'SET_FACTOR_ID'
const SET_SELECTED_MSISDN_ACTION_TYPE: string = 'SET_SELECTED_MSISDN_ID'
const SET_IS_PENDING_ACTION_TYPE: string = 'SET_IS_PENDING'
const SET_MARKET_ACTION_TYPE: string = 'SET_MARKET'
const SET_EMAIL_ENABLED_ACTION_TYPE: string = 'SET_EMAIL_ENABLED'
const SET_DXL_ERROR_ACTION_TYPE: string ='SET_DXL_ERROR'


export const setUsernameAction = (username: String): Action => {
    return ({
        type: SET_USERNAME_ACTION_TYPE,
        payload: username
    })
}

export const setPasswordAction = (password: String): Action => {
    return ({
        type: SET_PASSWORD_ACTION_TYPE,
        payload: password
    })
}

export const setLoginStatusAction = (status: LOGIN_STATUS): Action => {
    return ({
        type: SET_LOGIN_STATUS_TYPE,
        payload: status
    })
}

// export const setSelectedFactorAction = (factor: string): Action => {
//     return ({
//         type: SET_SELECTED_FACTOR_TYPE,
//         payload: factor
//     })
// }

export const setNavigateAction = (nav: any): Action => {
    return ({
        type: SET_NAVIGATE_ACTION_TYPE,
        payload: nav
    })
}

export const setFactorListAction = (factors: any): Action => {
    return ({
        type: SET_FACTOR_LIST_ACTION_TYPE,
        payload: factors
    })
}

export const setPendingFactorListAction = (factors: any): Action => {
    return ({
        type: SET_PENDING_FACTOR_LIST_ACTION_TYPE,
        payload: factors
    })
}

export const setFactorIdAction = (factorId: string): Action => {
    return ({
        type: SET_FACTOR_ID_ACTION_TYPE,
        payload: factorId
    })
}

export const setSelectedMSISDNAction = (msisdn: string): Action => {
    return ({
        type: SET_SELECTED_MSISDN_ACTION_TYPE,
        payload: msisdn
    })
}

export const setIsPendingAction = (pending: boolean): Action => {
    return ({
        type: SET_IS_PENDING_ACTION_TYPE,
        payload: pending
    })
}
export const setMarketAction = (market: string): Action => {
    return ({
        type: SET_MARKET_ACTION_TYPE,
        payload: market
    })
}
export const setEmailEnabledAction = (flag: boolean): Action => {
    return ({
        type: SET_EMAIL_ENABLED_ACTION_TYPE,
        payload: flag
    })
}

export const setDxlErrorAction = (msg: string): Action => {
    return ({
        type: SET_DXL_ERROR_ACTION_TYPE,
        payload: msg
    })
}


export function userdataReducer(state = InitialState, action: any) {
    switch (action.type) {
        case SET_USERNAME_ACTION_TYPE:
            return { ...state, username: action.payload }
        case SET_PASSWORD_ACTION_TYPE:
            return { ...state, password: action.payload }
        case SET_LOGIN_STATUS_TYPE:
            return { ...state, loginStatus: action.payload }
        case SET_NAVIGATE_ACTION_TYPE:
            return { ...state, navigate: action.payload }
        case SET_FACTOR_LIST_ACTION_TYPE:
            return { ...state, mfaFactors: action.payload }
        case SET_PENDING_FACTOR_LIST_ACTION_TYPE:
            return { ...state, pendingFactors: action.payload }
        case SET_SELECTED_MSISDN_ACTION_TYPE:
            return { ...state, selectedMSISDN: action.payload }
        case SET_FACTOR_ID_ACTION_TYPE:
            return { ...state, selectedFactorId: action.payload }
        case SET_IS_PENDING_ACTION_TYPE:
            return { ...state, isPending: action.payload }
        case SET_MARKET_ACTION_TYPE:
            return { ...state, market: action.payload }
        case SET_EMAIL_ENABLED_ACTION_TYPE:
            return {...state, emailEnabled:action.payload}
        case SET_DXL_ERROR_ACTION_TYPE:
            return {...state, dxlError:action.payload}
        default:
            return state
    }
}