import { connect } from 'react-redux'
import { RootState, store } from '../state/store'
import { setDxlErrorAction, setPasswordAction, setSelectedMSISDNAction, setUsernameAction } from '../state/userdata'
import { useNavigate } from 'react-router-dom'
import { GL, enroll, logFE, sendOTP } from '../services/services'
import { POPUPTYPE, setCurrentPopupAction, setWaitingAction } from '../state/appdata'
import { AuthFactor } from '../services/be_interfaces'
import { setChallengeIdAction } from '../state/serviceData'
import { clearMSISDNSelection, doEnroll, getMessageFromDXLError, isNumberMSISDN, isSelectedAValidEmail } from '../services/common-ui'
import { useEffect } from 'react'
import { tagInserisceiNumeroManualmenteClick, tagInserisciEmailManualmentePageTag, tagInserisciNumeroManualmentePageTag } from '../services/utag'
import { ErrorBox } from './ErrorBox'




const mapState = (state: RootState) => ({
    vodaprofile: state.vodaprofile,
    userdata: state.userdata,
    service: state.service
})


const mapDispatch = {
    selectMSISDN: (msisdn: string) => (setSelectedMSISDNAction(msisdn)),
    setPopup: (popup: POPUPTYPE) => (setCurrentPopupAction(popup)),
    setWaiting: (wait: boolean) => (setWaitingAction(wait)),
    setChallengeId: (challengeId: string) => (setChallengeIdAction(challengeId)),
    setDXLError:(msg:string)=>(setDxlErrorAction(msg)),
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch

type Props = StateProps & DispatchProps





const InsertEmailManuallyComponent = (props: Props) => {

    const navigate = useNavigate()


    useEffect(()=>{
        tagInserisciEmailManualmentePageTag();
    },[])

    // const getFactorsList = () => {
    //     let Items = []
    //     let i = 0
    //     for (let f of props.vodaprofile.bos) {
    //         Items.push(
    //             <option key={'' + i++} value={f}  >{f}</option>
    //         )
    //     }
    //     return Items
    // }



    const createFactor = (): AuthFactor => {
        return ({
            kind: 'email',
            label: 'default',
            profileEmail: {
                email: props.userdata.selectedMSISDN,
                language: 'it-IT'
            },
        })
    }


    const manageChange=(e:any)=>{
            props.selectMSISDN(e.target.value)
       
    }



    const restrictNumeric=(e:any)=>{
        //logFE('E=',e)      
        let c=e.key
        if(c!='Backspace' && c!='+' && (c<'0' || c>'9')){
            
            e.preventDefault()
        }
        
    }


    



    const sendCodeLocal = async (dispatch: any, getState: any) => {

        props.setWaiting(true)
        logFE('launching enroll')
        props.setDXLError("");
        await doEnroll('email').then(r=>
        {if(!r){
            props.setWaiting(false)
        }})
       
    }

    return (
        <div className="popup InsertNumber">
            <div className='closePopup' onClick={e =>{ 
                clearMSISDNSelection();
                props.setPopup('NONE');
                }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5.5 18.5L18.5 5.5M18.5 18.5L5.5 5.5" stroke="#0D0D0D" strokeLinecap="round" strokeLinejoin="round" />
            </svg></div>
            <div className='contentPopup'>
                <h3>{GL('ED_MFA_EMAIL_TITLE')}</h3>
                <p>{GL('ED_MFA_EMAIL_DES')}</p>
                {/* <p>{GL('ED_MFA_NUMBER_TEXT')}</p> */}


                <div className="input-field" id="inputSelectNumber">
                    <input id="insertNumber" type="text" pattern="[0-9]" 
                    placeholder='' 
                     onChange={manageChange} />
                    <label htmlFor="insertNumber">{GL('ED_MFA_NUMBER_TEXT')}</label>
                </div>
                {
                    
                    (props.userdata.dxlError && props.userdata.dxlError.length>0)?
                    (<ErrorBox text={getMessageFromDXLError(props.userdata.dxlError)} type='ERROR' />):(<div></div>)                
                }
                

                <div className='bottomSection'>
                    <div className={'actionbutton '+(isSelectedAValidEmail()?'':' disabled')}>
                        <button onClick={(e) => {
                            if(isSelectedAValidEmail()){
                                tagInserisceiNumeroManualmenteClick();
                                store.dispatch(sendCodeLocal)
                        }}}>{GL('ED_MFA_CONF_OTP_SEND')}</button>
                    </div>
                </div>
                {/* <button onClick={e => store.dispatch(sendCodeLocal)}>{GL('ED_MFA_CONF_OTP_SEND')}</button> */}
            </div>



        </div>
    )
}

// Typical usage: `connect` is called after the component is defined
export default connect(
    mapState,
    mapDispatch
)(InsertEmailManuallyComponent)