import { AddFactorRequest, AddFactorResponse, AuthFactor, ChallengeData, ChallengeResponse, DXLError, EnrollRequest, EnrollResponse, LoginStatusResponse, PortalLoginRequest, PortalLoginResponse, UserDetails, ValidateRequest, ValidateResponse } from "./be_interfaces";
import { DF_DUMMY, DF_DUMMY_ANSWER } from "./df_dummy";
import axios from 'axios';
import { mock_responses } from "./mock";
import { isDebug } from "../state/store";

let BASE_URL = '/loginmfa'

export const setBaseUrl = (baseURL: string) => {
    BASE_URL = baseURL
}



let SERVICE_MODE = 'MOCK'

let __IS_MOCK: boolean | undefined = undefined



export const isMock = (): boolean => {
    if (__IS_MOCK != undefined) {
        return __IS_MOCK
    } else {
        const searchParams: any = new URLSearchParams(document.location.search)
        if (searchParams.get('mock') && searchParams.get('mock') === 'true') {
            __IS_MOCK = true
            logFE('setting mock to true', isMock())
        } else {
            __IS_MOCK = false
        }
    }


    return __IS_MOCK
}



// export let SERVICE_MODE = 'REAL'

export let MOCK_SCENARIO = 'SIMPLE_LOGIN'

export const setMockScenario = (scenario: string) => {
    MOCK_SCENARIO = scenario
    logFE('mock scenario:', MOCK_SCENARIO)
}





interface StorageEntry{
    data:any,
    expiry:number
}


export const getFromLocalStorage=(entryName:string)=>{
    let entryString=localStorage.getItem(entryName)

    if(entryString){
        try {
            let entry:StorageEntry=JSON.parse(entryString)
            if(entry){
                let now =new Date().getTime()
                if(now<entry.expiry){
                    return entry.data
                }
            }
        } catch (error) {
            logFE('error unmarshalling datafile from local storage',error)
        }
    }
  
    return null
}

export const putOnLocalStorage=(label:string,data:any,ttl=(60*60*1000))=>{
    let entry:StorageEntry={
        data:data,
        expiry:(new Date().getTime() + ttl)  
      }
    localStorage.setItem(label,JSON.stringify(entry))

    }










export interface Factor {
    msisdn: string,
    default?: boolean
}

export interface LoginResponse {
    username: string,
    status: 'OK' | 'KO'
}

// export interface DXLUserData {
//     username: string,
//     factors: Factor[],
//     BOS: string[]
// }


export interface verifyOTPData {
    status: 'OK' | 'KO'
}

export interface sendOTPData {
    status: 'OK' | 'KO'
}

// let user =  referente.businessPRE4@mail.it
// let password = Vodafone123


export const login = async (username: string, password: string): Promise<PortalLoginResponse> => {
    return new Promise<PortalLoginResponse>(async (resolve: any, reject: any) => {
        logFE('u p=', username, password)
        if (isMock()) {
            setTimeout(() => {

                resolve(mock_responses[MOCK_SCENARIO].login)
            }, 200)
        } else {
            // perform real call

            // let URL = `http://www.vodafone.it/area-utente/sso/external/login?username=${username}&password=${password}&actionName=externalLogin&redirectURL=${encodeURIComponent('http://www.vodafone.it/area-utente/fai-da-te/Aziende/Aziende/Homehome.html')}`

            // let URL = `http://www.vodafone.it/area-utente/gazzo`

            let req: PortalLoginRequest = {
                userName: username,
                password: password
            }

            const headers = {
                'Content-type': 'application/json'
            }

            let URL = `${BASE_URL}/portalLogin`


            // con


            axios.post(URL, JSON.stringify(req), {
                withCredentials: true,
                headers: headers
            }).then(d => {
                logFE(d);
                let resp: PortalLoginResponse = {}
                if (d && d.status) {
                    if (d.data) {
                        try {
                            resp = d.data
                        } catch (error) {
                            logFE('error during portalLogin response Parsing', d)
                        }
                        resp.status = d.status;
                    }
                    if (d.headers['location']) {
                        resp.location = d.headers['location']
                    }
                    resolve(resp)

                } else {
                    resolve({ status: 500 })
                }
            }).catch((e) => {
                logFE('e,response', e, e.response)
                let d = e.response
                if (d) {
                    let resp: PortalLoginResponse = {
                        status: d.status
                    }
                    if (d.data) {
                        if (d.data.headers && d.data.headers['location']) {
                            resp.location = d.data.headers['location']
                        }
                        if(d.data.location){
                            resp.location=d.data.location
                        }
                        if (d.data.errorCode) {
                            resp.errorCode = d.data.errorCode
                        }
                    }

                    if (d.status in [401, 403]) {
                        resolve(resp)
                    } else {
                        resolve(resp)
                    }
                    logFE('in login catch', e.response)
                }

                reject(e)
            })






        }

    })
}


// export const sendCode = async () => {
//     return new Promise((resolve: any, reject: any) => {
//         if (isMock()) {
//             setTimeout(() => {
//                 resolve({ status: 'OK' })
//             }, 2000)
//         } else {
//             // perform real call
//         }

//     })
// }



export const retrieveUser = async (username: string): Promise<UserDetails> => {
    logFE(`retrieveUSER:  ${username}`)
    return new Promise<UserDetails>((resolve: any, reject: any) => {
        if (isMock()) {
            setTimeout(() => {
                resolve(mock_responses[MOCK_SCENARIO].retrieveUser)
            }, 2000)
        } else {
            // perform real call
            // perform real call


            axios.get(
                `${BASE_URL}/users/${username}`,
                {
                    withCredentials: true
                }
            ).then((resp) => {
                logFE('DXL response:', resp.data)
                if (resp.status == 200) {

                    let rr: any = ((resp.data) as UserDetails)
                    if (rr.authFactors) {
                        for (let f of rr.authFactors) {
                            if (f.profileSMS) {
                                f.profileSms = f.profileSMS
                            }
                        }
                    }

                    rr.status = 'OK'
                    logFE('returning User:', rr)
                    resolve(rr)

                } else {
                    // validation failed
                    resolve({ status: 'KO' })
                }
            }).catch((err) => {
                logFE("user response:", err.response)
                if (err.response && err.response.data) {
                    let rr = ((err.response.data) as UserDetails)
                    logFE('err.response.status', err.response.status)
                    if (err.response.status == 404) {
                        try {
                            rr = {}
                            logFE('about to parse:', err.response.data)
                            rr = err.response.data
                            logFE('after parsing')
                        } catch (error) {
                            logFE('error parsing user response', error)
                        }
                    }
                    rr.callStatus = 'OK'
                    logFE('returning resp:', rr)

                    resolve(rr)
                } else {
                    reject(err)
                }

            })


        }

    })
}

export const sendOTP = async (username: string, factor: AuthFactor | null, authToken: string): Promise<ChallengeResponse> => {
    logFE("createChallenge", username, factor)
    return new Promise<ChallengeResponse>((resolve: any, reject: any) => {
        if (isMock()) {
            setTimeout(() => {
                resolve(mock_responses[MOCK_SCENARIO].createChallenge)
            }, 2000)
        } else {
            // perform real call
            let req: ChallengeData = {
                factor: { id: (factor && factor.id) || '' },
                authToken: authToken,
                user: { username: username }
            }

            const headers = {
                'Content-type': 'application/json'
            }
            axios.post(
                `${BASE_URL}/createChallenge`,
                JSON.stringify(req), {
                headers: headers,
                withCredentials: true
            }
            ).then((resp) => {
                if (resp.status == 200) {
                    let rr: any = (resp.data as ValidateResponse)
                    rr.status = 'OK'
                    resolve(rr)

                } else {
                    // validation failed
                    let tresp={status:'KO'};
                    if(resp.data){
                        tresp={...resp.data,...tresp}
                    }
                    resolve(tresp)
                }
            }).catch((err) => {
                reject(err)
            })



        }

    })
}



export const verifyOTP = async (username: string, code: string, challengeId: string, authToken: string,otp_type:string): Promise<verifyOTPData> => {
    logFE('verifyOTO:', code)
    return new Promise<verifyOTPData>((resolve: any, reject: any) => {
        if (isMock()) {
            setTimeout(() => {
                resolve(mock_responses[MOCK_SCENARIO].verifyOTP)
                logFE("setting cookies")
            }, 200)
        } else {
            // perform real call
            let req: ValidateRequest = {
                authToken: authToken,
                otp: code,
                challengeId: challengeId,
                otpType:    otp_type,
                user: {
                    username: username
                }
            }

            const headers = {
                'Content-type': 'application/json'
            }
            axios.post(
                `${BASE_URL}/validate`,
                JSON.stringify(req), {
                withCredentials: true,
                headers: headers
            }
            ).then((resp) => {
                if (resp.status == 200) {
                    let rr: any = (resp.data as ValidateResponse)
                    rr.status = 'OK'
                    resolve(rr)

                } else {
                    // validation failed
                    resolve({ status: 'KO' })
                }
            }).catch((err) => {
                reject(err)
            })


        }

    })
}


export const enroll = async (username: string, factor: AuthFactor, authToken: string): Promise<EnrollResponse | undefined> => {
    logFE('enroll2:', username, factor)
    return new Promise<EnrollResponse>((resolve: any, reject: any) => {
        if (isMock()) { 

            let msisdn = factor.profileSms?.msisdn || factor.profileEmail?.email

            if(factor.kind.toLowerCase()==='sms'){
                if (msisdn?.indexOf('00') == 0) {
                    msisdn = msisdn!.replace('00', '+')
                } else if (msisdn?.indexOf('+') != 0) {
                    msisdn = '+39' + msisdn;
                }
    
            }
           
            logFE('setting nsisdn to:', msisdn)

        

                let resp:any=mock_responses[MOCK_SCENARIO].enroll
                logFE('enroll response:', mock_responses[MOCK_SCENARIO])
                if(resp.error){
                    reject(resp)
                }else{
                    resolve(resp)
                }
                
           
        } else {

            let msisdn = factor.profileSms?.msisdn || factor.profileEmail?.email

            if(factor.kind.toLowerCase()==='sms'){
                if (msisdn?.indexOf('00') == 0) {
                    msisdn = msisdn!.replace('00', '+')
                } else if (msisdn?.indexOf('+') != 0) {
                    msisdn = '+39' + msisdn;
                }
    
            }


            const reqFactor:any= {
                kind:factor.kind,
               
            }
            if(reqFactor.kind.toLowerCase()==='sms'){
                reqFactor.profileSms= {
                    msisdn: msisdn,
                    lang: factor.profileSms?.language
                }
           
            }else if(reqFactor.kind.toLowerCase()==='email'){
                reqFactor.profileEmail= {
                    email: msisdn,
                    lang: factor.profileEmail?.language
                }
            }

            // perform real call
            let req: EnrollRequest = {
                user: {
                    username: username,
                    name: '',
                    surname: ''
                },
                authToken: authToken,
                label: 'primario',
                factor: reqFactor
            }

            const headers = {
                'Content-type': 'application/json',
                'vf-a-customer-identification': authToken
            }
            logFE('calling enroll', `${BASE_URL}/enroll`, 'request:', req);
            axios.post(
                `${BASE_URL}/enroll`,
                JSON.stringify(req),
                {
                    withCredentials: true,
                    headers: headers
                }
            ).then((resp) => {
                if (resp.status < 299) {
                    let rr: any = resp.data
                    rr.status = 'OK'
                    resolve(rr)
                } else {
                    reject(resp.data)
                }
            }).catch((err) => {
                const resp=err.response
                let rejectResponse=err
                if(resp && resp.status<499 && resp.data){
                    rejectResponse=resp.data
                }
                reject(rejectResponse)
            })

        }

    })
}


export const addFactor = async (username: string, factor: AuthFactor, authToken: string): Promise<AddFactorResponse | undefined> => {
    logFE('addFactor:', username, factor)
    return new Promise<AddFactorResponse>((resolve: any, reject: any) => {
        if (isMock()) {

            let msisdn = factor.profileSms?.msisdn || factor.profileEmail?.email

            if(factor.kind.toLowerCase()==='sms'){
                if (msisdn?.indexOf('00') == 0) {
                    msisdn = msisdn!.replace('00', '+')
                } else if (msisdn?.indexOf('+') != 0) {
                    msisdn = '+39' + msisdn;
                }
    
            }

            logFE('setting nsisdn to:', msisdn)

          


                logFE('add Factor response:', mock_responses[MOCK_SCENARIO].addFactor)
                let resp: AddFactorResponse|DXLError | undefined = mock_responses[MOCK_SCENARIO].addFactor
                logFE('enroll response:', mock_responses[MOCK_SCENARIO])
                if((resp as any) .error){
                    reject(resp)
                }else{
                    resolve(resp)
                }
                
              
      
        } else {

            let msisdn = factor.profileSms?.msisdn || factor.profileEmail?.email

            if(factor.kind.toLowerCase()==='sms'){
                if (msisdn?.indexOf('00') == 0) {
                    msisdn = msisdn!.replace('00', '+')
                } else if (msisdn?.indexOf('+') != 0) {
                    msisdn = '+39' + msisdn;
                }
            }

            
            const reqFactor:any= {
                kind:factor.kind,
               
            }
            if(reqFactor.kind.toLowerCase()==='sms'){
                reqFactor.profileSms= {
                    msisdn: msisdn,
                    lang: factor.profileSms?.language
                }
           
            }else if(reqFactor.kind.toLowerCase()==='email'){
                reqFactor.profileEmail= {
                    email: msisdn,
                    lang: factor.profileEmail?.language
                }
            }
            // perform real call
            let req: AddFactorRequest = {
                user: {
                    username: username,
                    name: '',
                    surname: ''
                },
                authToken: authToken,
                label: 'primario',
                factor: reqFactor
            }

            const headers = {
                'Content-type': 'application/json',
                'vf-a-customer-identification': authToken
            }
            logFE('calling addFactor', `${BASE_URL}/addFactor`, 'request:', req);
            axios.post(
                `${BASE_URL}/addFactor`,
                JSON.stringify(req),
                {
                    withCredentials: true,
                    headers: headers
                }
            ).then((resp) => {
                if (resp.status < 299) {
                    let rr: any = resp.data
                    rr.status = 'OK'
                    resolve(rr)
                } else {
                    reject(resp.data)
                }
            }).catch((err) => {
                const resp=err.response
                let rejectResponse=err
                if(resp && resp.status<499 && resp.data){
                    rejectResponse=resp.data
                }
                reject(rejectResponse)
            })

        }

    })
}


let DF: { [id: string]: string; } = {}



export const GL = (label: any): string => {
    if (DF[label]) {
        let val = DF[label]
        // if (val) {
        //     const parser = new DOMParser();
        //     const doc = parser.parseFromString(val, "application/xml");
        //     return doc.documentElement.nodeName
        // }
        //val=JSON.stringify(val).slice(1,-1)
        // logFE('label after transform:',label)
        let decoded = val.replace(/\u0026/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/""/g, '"').replace(/\&quot;/g, '"').replace(/\&amp;/g, '&');
        // if ('ED_MFA_HD_VF_LOGO' === label)
        //     logFE('decoded', decoded)

        return decoded
    }
    return ''
}


export function RP(template: string, ...args: string[]) {

    for (let i = 0; i < args.length; i++) {
        template = template.replace(`{${i}}`, args[i])
    }
    return template
}


export const offuscate = (master: string, lastvisible = 4) => {
    let answer = '';
    if(!master || master.length<1){
        return master
    }
    for (let i = 0; i < master.length - lastvisible; i++) {
        answer += '*'
    }
    for (let j = master.length - lastvisible; j < master.length; j++) {
        answer += master[j]
    }
    return answer

}




const getDataFileFromElementList = (elList: any) => {
    let ldf: any = {}
    for (let e of elList) {
        ldf[e.elementName] = e.elementValue
    }
    return ldf
}


export const getDFFromStorage=()=>{
    let df= getFromLocalStorage('DF')
    if(df){
        DF=df
    }
    return df
}

export const saveDFInStorage=(df:any)=>{
    return putOnLocalStorage('DF',df)
}

export const loadUCMDatafile = () => {
    return new Promise((resolve, reject) => {
        if (isMock()) {
            logFE("loading DF from mock")
            setTimeout(() => {
                DF = getDataFileFromElementList(DF_DUMMY_ANSWER.elementList)
                resolve(DF)

            }, 150)
        } else {

            logFE("loading DF from server")
            axios.get('https://www.vodafone.it/area-utente/getUcmContent?dataFileId=VIT_DF_MFA_IT&format=json',
            {withCredentials:false}).then((resp) => {
                logFE("resp=", resp)
                logFE("ELS=", resp && resp.data && resp.data.elementList)

                let elements = (resp && resp.data && resp.data.elementList) || DF_DUMMY_ANSWER


                if (resp && resp.data && resp.data.elementList) {
                    DF = getDataFileFromElementList(resp.data.elementList)
                    logFE('DF=', DF)
                } else {
                    DF = getDataFileFromElementList(DF_DUMMY_ANSWER)
                }

                logFE("DF=", DF)
                resolve(DF)
            }).catch((e) => {
                DF = DF_DUMMY
                resolve(DF)
            })
        }
    })
}

// for now:
export const NOT_LOGGED = 'NOT_LOGGED'
export const LOGGED_NOT_AUTH = 'LOGGED_NOT_AUTH'
export const LOGGED = 'LOGGED'

export const loginStatus = () => {

    logFE('loginStatus:')
    return new Promise<LoginStatusResponse>((resolve: any, reject: any) => {
        if (isMock()) {
            logFE('calling loginStatus on mock')
            setTimeout(() => {
                logFE('logstatus response:', mock_responses[MOCK_SCENARIO])
                let resp: LoginStatusResponse = mock_responses[MOCK_SCENARIO].loginStatus || { loginStatus: NOT_LOGGED }

                resolve(resp)
            }, 200)
        } else {
            // perform real call
            logFE('calling loginStatus on server')
            axios.get(
                `${BASE_URL}/loginStatus`,
                { withCredentials: true }
            ).then((resp) => {
                if (resp.status < 299) {
                    // let rr: any = JSON.parse(resp.data)
                    resolve(resp.data)
                } else {
                    reject(resp.data)
                }
            }).catch((err) => {
                reject(err)
            })

        }

    })
}


export const logFE = (...args: any[]) => {
    if (isDebug()) {
        console.log(...args);
    }
    // 👉️ ['a', 'b', 'c']

}