import { connect } from 'react-redux'
import { RootState, store } from '../state/store'
import { setPasswordAction, setSelectedMSISDNAction, setUsernameAction } from '../state/userdata'
import { useNavigate } from 'react-router-dom'
import { GL, enroll, logFE, sendOTP } from '../services/services'
import { POPUPTYPE, setCurrentPopupAction, setWaitingAction } from '../state/appdata'
import { AuthFactor } from '../services/be_interfaces'
import { setChallengeIdAction } from '../state/serviceData'
import { clearMSISDNSelection, doEnroll, isNumberMSISDN } from '../services/common-ui'
import { useEffect } from 'react'
import { tagInserisceiNumeroManualmenteClick, tagInserisciNumeroManualmentePageTag } from '../services/utag'




const mapState = (state: RootState) => ({
    vodaprofile: state.vodaprofile,
    userdata: state.userdata,
    service: state.service
})


const mapDispatch = {
    selectMSISDN: (msisdn: string) => (setSelectedMSISDNAction(msisdn)),
    setPopup: (popup: POPUPTYPE) => (setCurrentPopupAction(popup)),
    setWaiting: (wait: boolean) => (setWaitingAction(wait)),
    setChallengeId: (challengeId: string) => (setChallengeIdAction(challengeId))
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch

type Props = StateProps & DispatchProps





const InsertNumberManuallyComponent = (props: Props) => {

    const navigate = useNavigate()


    useEffect(()=>{
        tagInserisciNumeroManualmentePageTag();
    },[])

    const getFactorsList = () => {
        let Items = []
        let i = 0
        for (let f of props.vodaprofile.bos) {
            Items.push(
                <option key={'' + i++} value={f}  >{f}</option>
            )
        }
        return Items
    }



    // const createFactor = (): AuthFactor => {
    //     return ({
    //         kind: 'sms',
    //         label: 'default',
    //         profileSms: {
    //             msisdn: props.userdata.selectedMSISDN,
    //             language: 'it-IT'
    //         },
    //     })
    // }


    const manageChange=(e:any)=>{
            props.selectMSISDN(e.target.value)
       
    }



    const restrictNumeric=(e:any)=>{
        //logFE('E=',e)      
        let c=e.key
        if(c!='Backspace' && c!='+' && (c<'0' || c>'9')){
            
            e.preventDefault()
        }
        
    }


    



    const sendCodeLocal = (dispatch: any, getState: any) => {

        props.setWaiting(true)
        logFE('launching enroll')
        doEnroll('sms')
       
    }

    return (
        <div className="popup InsertNumber">
            <div className='closePopup' onClick={e =>{ 
                clearMSISDNSelection();
                props.setPopup('NONE');
                }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5.5 18.5L18.5 5.5M18.5 18.5L5.5 5.5" stroke="#0D0D0D" strokeLinecap="round" strokeLinejoin="round" />
            </svg></div>
            <div className='contentPopup'>
                <h3>{GL('ED_MFA_NUMBER_TITLE')}</h3>
                <p>{GL('ED_MFA_NUMBER_DES')}</p>
                {/* <p>{GL('ED_MFA_NUMBER_TEXT')}</p> */}


                <div className="input-field" id="inputSelectNumber">
                    <input id="insertNumber" type="text" pattern="[0-9]" placeholder='' onKeyDown={restrictNumeric} onChange={manageChange} />
                    <label htmlFor="insertNumber">{GL('ED_MFA_NUMBER_TEXT')}</label>
                </div>


                <div className='bottomSection'>
                    <div className={'actionbutton '+(isNumberMSISDN()?'':' disabled')}>
                        <button onClick={(e) => {
                            if(isNumberMSISDN()){
                                tagInserisceiNumeroManualmenteClick();
                                store.dispatch(sendCodeLocal)
                        }}}>{GL('ED_MFA_CONF_OTP_SEND')}</button>
                    </div>
                </div>
                {/* <button onClick={e => store.dispatch(sendCodeLocal)}>{GL('ED_MFA_CONF_OTP_SEND')}</button> */}
            </div>



        </div>
    )
}

// Typical usage: `connect` is called after the component is defined
export default connect(
    mapState,
    mapDispatch
)(InsertNumberManuallyComponent)