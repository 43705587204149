import { connect } from 'react-redux'
import { RootState, store } from '../state/store'
import { setDxlErrorAction, setSelectedMSISDNAction } from '../state/userdata'
import { useNavigate } from 'react-router-dom'
import { GL, logFE } from '../services/services'
import { POPUPTYPE, setCurrentPopupAction, setWaitingAction } from '../state/appdata'
import { useEffect, useState } from 'react'
import { CustomListItem, CustomSelect } from './CustomSelect'
import { clearMSISDNSelection, doEnroll, isNumberMSISDN } from '../services/common-ui'
import { tagEnrollSelectNumberInviaCodiceClick, tagEnterEmailManuallyClick, tagEnterNumberManuallyClick } from '../services/utag'
import { Factor, FactorType } from '../services/be_interfaces'




const mapState = (state: RootState) => ({
    vodaprofile: state.vodaprofile,
    userdata: state.userdata,
    service: state.service
})


const mapDispatch = {
    // selectFactor: (factorid: string) => (setSelectedFactorAction(factorid)),
    selectMSISDN: (msisdn: string) => (setSelectedMSISDNAction(msisdn)),
    setPopup: (popup: POPUPTYPE) => (setCurrentPopupAction(popup)),
    setWaiting: (wait: boolean) => (setWaitingAction(wait)),
    setDXLError:(msg:string)=>(setDxlErrorAction(msg)),
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch

type Props = StateProps & DispatchProps





const EnrollSetNumberComponent = (props: Props) => {


    const [acc1, setAcc1] = useState(true)

    const navigate = useNavigate()


  
  
    const isNumEmpty=()=>{
        let isNumEmpty=false
        try {
            // props.selectMSISDN(props.vodaprofile.bos[0])
            let nums = [...props.vodaprofile.bos, ...props.vodaprofile.contactNums]
            if (!nums || nums.length < 1) {
                isNumEmpty=true
            }
        } catch (error) {
            
        }
        return isNumEmpty
    }


    useEffect(() => {
        if(!props.userdata.emailEnabled){
            props.selectMSISDN(props.vodaprofile.bos[0])
            let nums = [...props.vodaprofile.bos, ...props.vodaprofile.contactNums]
            if (!nums || nums.length < 1) {
                props.setPopup('INSERISCI_NUMERO_MANUALMENTE')
            }
        }
        
    }, [])


    const getFactorsList = () => {
        let Items: any[] = [
            (<option key='' value=''  >Seleziona un numero</option>)
        ]
        let i = 0
        // props.selectFactor(props.vodaprofile.bos[0])
        for (let f of [...props.vodaprofile.bos, ...props.vodaprofile.contactNums]) {
            Items.push(
                <option key={'' + i++} value={f}  >{f}</option>
            )
        }
        return Items
    }

    const getNumbers = (): CustomListItem[] => {
        let Items: CustomListItem[] = []
        let i = 0
        // props.selectFactor(props.vodaprofile.bos[0])
        for (let f of [...props.vodaprofile.bos, ...props.vodaprofile.contactNums]) {
            Items.push({
                key: f,
                label: f,
                object: f
            }

            )
        }
        return Items
    }



    const sendCodeLocal = (dispatch: any, getState: any) => {
        props.setWaiting(true)
        const fid=props.userdata.selectedFactorId
        const facts=props.userdata.mfaFactors.filter((f:Factor)=>f.id===fid)
        let type:FactorType='sms'
        if(facts && facts.length>0){
            type=facts[0].type
        }
        props.setDXLError("");
        doEnroll(type)


    }



    const getInsertManuallyLabel=()=>{

        

        if(props.userdata.emailEnabled && isNumEmpty()){
            return GL('ED_MFA_CONF_MANUAL_NO_LIST');
        }else if((!props.userdata.emailEnabled) && isNumEmpty()){
            return GL('ED_MFA_CONF_NO_LIST_NO_MAIL');
        }

        if(props.userdata.emailEnabled){
            return GL('ED_MFA_CONF_MANUALLY');
        }else{
            return GL('ED_MFA_CONF_LIST_NO_MAIL');
            
        }
        
    }




    return (
        <div>
            <div className='mainContent'>

                <h3>{GL('ED_MFA_CONF_TITLE')}</h3>
                {(!isNumEmpty())?( <h5 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_CONF_DES') }}></h5>
                ):<div/>}
                <div className='centeredContent'>
                {(!isNumEmpty())?(
                    <CustomSelect label={props.vodaprofile.bos.length > 0 ? GL('ED_MFA_CONF_NUMBER') : GL('ED_MFA_CONF_CONTACT_NUM')} items={getNumbers()}
                        onSelection={(i) => {
                            props.selectMSISDN(i.label || '')
                        }} />):null
                       
                }
                    {/* <div className="input-field" id="inputPassword">
                        <select id='selectFactor' onChange={e => props.selectMSISDN(e.target.value)} placeholder='' value={props.userdata.selectedMSISDN}>
                            {getFactorsList()}
                        </select>
                        <label htmlFor="selectFactor">{GL('ED_MFA_CONF_NUMBER')}</label>
                    </div> */}




                    <div className='insert_manually_legend'>{getInsertManuallyLabel()}</div>
                    {/* <p className='listLink'><a
                        onClick={e => {
                            tagEnterNumberManuallyClick();
                            props.selectMSISDN('');
                            clearMSISDNSelection();
                            props.setPopup('INSERISCI_NUMERO_MANUALMENTE');
                        }}>{GL('ED_MFA_CONF_MANUALLY')}</a></p> */}

                    <div className='buttonPane'>
                        <button 
                        onClick={e => {
                            tagEnterNumberManuallyClick();
                            props.selectMSISDN('');
                            clearMSISDNSelection();
                            props.setPopup('INSERISCI_NUMERO_MANUALMENTE');
                        }}
                        > {GL('ED_MFA_CONF_NUM_BUTTON')}</button>
                       {props.userdata.emailEnabled? <button
                         onClick={e => {
                            tagEnterEmailManuallyClick();
                            props.selectMSISDN('');
                            clearMSISDNSelection();
                            props.setPopup('INSERISCI_EMAIL_MANUALMENTE');
                        }}
                        >{GL('ED_MFA_CONF_EMAIL_BUTTON')}</button>:null
                    }
                    </div>
                </div>

                {/* <div className='bigline' onClick={e => props.setPopup('INSERISCI_NUMERO_MANUALMENTE')}><a>{GL('ED_MFA_CONF_MANUALLY')}</a></div> */}

            </div>
            <div className="secondaryContent">

                <h3 dangerouslySetInnerHTML={{ __html: GL('ED_MFA_CONF_HELP_NEEDED') }}></h3>
                <div className="accordion accordionSE">
                    <ul>
                        <li id="accordionPrivato" onClick={e => {
                            setAcc1(!acc1)
                        }}>
                            <span dangerouslySetInnerHTML={{ __html: GL('ED_MFA_CONF_HELP_DES') }}></span>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" id="arrowPrivato"
                                className={acc1 ? "arrow arrowDown" : "arrow arrowUp"}
                            >
                                <g id="Navigation &#38; Actions/chevron-down">
                                    <path id="Vector" d="M3.5 16.75L12 8.25L20.5 16.75" stroke="#E60000" strokeMiterlimit="10" strokeLinecap="round" />
                                </g>
                            </svg>
                            <div className="accordionContent" id="contentPrivato" dangerouslySetInnerHTML={{ __html: GL('ED_MFA_CONF_HELP_DES_ACC') }}></div>
                        </li>
                    </ul>
                </div>
                <div className='spacer30'></div>
                <div className='lowerBand'>
                    <div className={'actionsButton' + (isNumberMSISDN() ? '' : ' disabled')}>
                        <button onClick={e => {
                            logFE('pshed send code')

                            if (props.userdata.selectedMSISDN && props.userdata.selectedMSISDN.length > 1) {
                                tagEnrollSelectNumberInviaCodiceClick();
                                store.dispatch(sendCodeLocal)
                            }
                        }}>{GL('ED_MFA_CONF_OTP_SEND')}</button>
                    </div>
                </div>
            </div>

        </div >

    )
}

// Typical usage: `connect` is called after the component is defined
export default connect(
    mapState,
    mapDispatch
)(EnrollSetNumberComponent)