import { connect } from 'react-redux'
import { RootState, store } from '../state/store'
import { setFactorIdAction, setPasswordAction, setSelectedMSISDNAction, setUsernameAction } from '../state/userdata'
import { useNavigate } from 'react-router-dom'
import { GL, offuscate, sendOTP } from '../services/services'
import { POPUPTYPE, setCurrentPopupAction, setWaitingAction } from '../state/appdata'
import { AuthFactor } from '../services/be_interfaces'
import { setChallengeIdAction } from '../state/serviceData'
import { CustomListItem, CustomSelect } from './CustomSelect'
import { useEffect } from 'react'
import { tagSelezionaNuovoNumeroInviaCodiceTag, tagSelezionaNuovoNumeroPage } from '../services/utag'
import { getMSISDNFromFactor } from '../services/common-ui'




const mapState = (state: RootState) => ({
    userdata: state.userdata,
    service: state.service
})


const mapDispatch = {
    selectMSISDN: (msisdn: string) => (setSelectedMSISDNAction(msisdn)),
    setPopup: (popup: POPUPTYPE) => (setCurrentPopupAction(popup)),
    setWaiting: (wait: boolean) => (setWaitingAction(wait)),
    setChallengeId: (challengeId: string) => (setChallengeIdAction(challengeId)),
    setSelectedFactorId: (factorId: string) => (setFactorIdAction(factorId)),
}
type StateProps = ReturnType<typeof mapState>
type DispatchProps = typeof mapDispatch

type Props = StateProps & DispatchProps

const SelezionaNuovoNunmeroComponent = (props: Props) => {

    const navigate = useNavigate()

    useEffect(()=>{
        try {
            tagSelezionaNuovoNumeroPage();
        } catch (error) {
            
        }

        
    },[])

    const getFactorsList = () => {
        let Items = []
        let i = 0

        for (let f of props.userdata.mfaFactors) {
            if ((f && f.profileSMS && f.profileSMS.msisdn)||(f.profileEmail && f.profileEmail.email)) {
                let lab=(f && f.profileSMS && f.profileSMS.msisdn)||(f.profileEmail && f.profileEmail.email);
                Items.push(
                    <option key={'' + i++} value={lab}  >{lab}</option>
                )
            }

        }
        return Items
    }

    let defaultItem=null

    const getNumbers = () => {
        let Items: CustomListItem[] = []
        let i = 0

        for (let f of props.userdata.mfaFactors) {
            let lab=null;
            let orig=null;
            if(!lab){
                orig=( f.profileSms && f.profileSms.msisdn)
                lab=offuscate(orig,4);
              
            }
            if(!lab){
                orig=( f.profileEmail && f.profileEmail.email)
                lab=offuscate(orig,orig.length-5);
            }
            

            if (f && lab) {
                let item= {
                    label: lab,
                    key: f.id,
                    object: f
                }
                Items.push(
                   item
                )
                if(orig===props.userdata.selectedMSISDN){
                    defaultItem=item;
                }
            }


        }
        return Items
    }



    const sendCodeLocal = (dispatch: any, getState: any) => {
        props.setWaiting(true);
        tagSelezionaNuovoNumeroInviaCodiceTag();

        let factor = null;
        // get factorId
        for (let fa of (props.userdata.mfaFactors as AuthFactor[])) {

            if ( 
                ((fa.profileSms?.msisdn === props.userdata.selectedMSISDN)
               ||  (fa.profileEmail?.email === props.userdata.selectedMSISDN)
            )) {
                factor = fa
            }
        }
        if (factor) {
            props.setSelectedFactorId(factor.id||'');
            sendOTP(props.userdata.username, factor, props.service.authToken).then(s => {
                if (s.id) {
                    props.setChallengeId(s.id)
                }
                props.setWaiting(false)
                props.setPopup('VERIFICA_IDENTITA');
            }).catch(e => {
                props.setWaiting(false)
            })
        }else{
            props.setWaiting(false);
        }


    }

    return (

        <div className="popup SelectNumber">
            <div className='closePopup' onClick={e => props.setPopup('NONE')}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5.5 18.5L18.5 5.5M18.5 18.5L5.5 5.5" stroke="#0D0D0D" strokeLinecap="round" strokeLinejoin="round" />
            </svg></div>
            <div className='contentPopup'>
                
                <h3>{GL('ED_MFA_NEW_NUM_TITLE')}</h3>
                <p dangerouslySetInnerHTML={{ __html: GL('ED_MFA_NEW_NUM_DES') }}></p>
                {/* <p>{GL('ED_MFA_NEW_NUM_LIST')}</p> */}
                {/* <select onChange={e => props.selectFactor(e.target.value)} value={props.userdata.selectedFactor}>
                    {getFactorsList()}
                </select> */}
                <CustomSelect label={GL('ED_MFA_NEW_NUM_LIST')} items={getNumbers()}
                    onSelection={(i) => {
                        props.selectMSISDN(getMSISDNFromFactor(i.object) || '')
                        props.setSelectedFactorId(i.object.id)

                    }} 
                    default={defaultItem}
                    />
                {/* <div className="input-field" id="inputSelectNumber">
                    <select id='selectFactor' onChange={e => props.selectMSISDN(e.target.value)} placeholder='' value={props.userdata.selectedMSISDN}>
                        {getFactorsList()}
                    </select>
                    <label htmlFor="selectFactor">{GL('ED_MFA_NEW_NUM_LIST')}</label>
                </div> */}
                <div className='bottomSection'>
                    <div className='actionbutton'>
                        <button onClick={e => store.dispatch(sendCodeLocal)}>{GL('ED_MFA_CONF_OTP_SEND')}</button>
                    </div>
                    <p>{GL('ED_MFA_NEW_NUM_HELP')}</p>

                    {/* /ED_MFA_NEW_NUM_OPERATOR */}
                   {/*  
                   <a onClick={e => {

                    }}>{GL('ED_MFA_NEW_NUM_OPERATOR')}</a>
                   */} 

                    <span dangerouslySetInnerHTML={{__html:GL('ED_MFA_NEW_NUM_OPERATOR')}}></span>

                </div>


            </div>



        </div>
    )
}

// Typical usage: `connect` is called after the component is defined
export default connect(
    mapState,
    mapDispatch
)(SelezionaNuovoNunmeroComponent)