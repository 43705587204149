import { useEffect, useRef, useState } from "react"
import { GL, logFE } from "../services/services"

export interface CustomListItem {
    object?: any;
    key?: string;
    label?: string;
    orig?:string;
}

let a: CustomListItem = {
    label: ''
}

interface LocalPropsType {
    label: string;
    items: CustomListItem[];
    onSelection: (item: CustomListItem) => void;
    default?:CustomListItem|null
}



// let LocalProps = {
//     onSelection: (item: CustomListItem) => { },
//     label: "",
//     items: []
// }


// type Props = typeof LocalProps

export const CustomSelect = (props: LocalPropsType) => {
    const [acc2, setAcc2] = useState(false)
    const [sel, setSel] = useState(props.default?props.default:a)

    

    // useEffect(() => {
    //     logFE('props:', props)
    //     if (props.items.length > 0) {
    //         setSel(props.items[0])
    //     }
    // }, [])

    const getItems = () => {
        let Items: any[] = []
        for (let i of (props.items as CustomListItem[])) {
            Items.push(
                <li key={i.key} onClick={(e) => {
                    setSel(i)
                    props.onSelection(i)
                    setAcc2(false)
                }}>
                    {i.label}
                </li>
            )
        }

        return Items

    }


    return (

        <div className="customSelect">
            <div className="input-field">
                <input id='username' value={(sel && sel.label)} placeholder={GL("ED_MFA_CONF_NUM_TEXT")} disabled onClick={e => {
                    setAcc2(!acc2)
                }} />
                <label htmlFor="username" dangerouslySetInnerHTML={{ __html: props.label }}  ></label>

                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" className={'info-icon ' + (acc2 ? ("arrow arrowDown") : ("arrow arrowUp"))} onClick={e => {
                    if(props.items.length>0){
                        // if list is not empty
                        setAcc2(!acc2)
                    }else{
                        logFE('no numbers to show... disabling list box',props)
                    }
                }}>
                    <path d="M21 7.75L12.5 16.25L4 7.75" stroke="#262626" strokeMiterlimit="10" strokeLinecap="round" />
                </svg>
            </div>
            {
                (acc2) ? (
                    <div className="input-field">
                        <ul className="options">
                            {getItems()}
                        </ul>
                    </div>

                ) : (
                    <div></div>
                )
            }

        </div>




    )
}